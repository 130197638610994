<template>
  <!-- default container -->
  <div
    :style="{ backgroundImage: 'url(' + require('@/assets/enrollment_bg.jpg') + ')' }"
    style="background-position: top; background-size: auto; background-repeat: no-repeat;"
  >

    <div style="text-align: center; color: white; padding: 25px;">
      
      <div style="max-width: 600px; margin-left: auto; margin-right: auto;">
        <img src="@/assets/gravity_logo_sm.png" style="padding: 20px;">
        <h1 style="margin-top: 10px; margin-bottom: 0px;">
          Let's grab their attention.
        </h1>
        <div style="width: 100%; display: flex; flex-direction: column; font-size: 0.9em;">
          <div style="width: 100%; text-align: left;">
            <p>Fill out the form below to enroll in this 100% T3AC & ARCP eligible email solution. This automated email marketing program re-engages unsold leads up to 60 days after the shopper’s original interaction and prospects who have recently moved into your area.</p>
            <h2 style="margin-top: 30px;">Exclusively for Stellantis dealerships</h2>
            <ul>
              <li>$99 for emails less than 100 email sends</li>
              <li>$149 for unlimited email sends</li>
            </ul>
          </div>     
          <div
            v-if="showButtons"
            class="w-100 d-flex flex-row justify-content-center"
            style="margin-top: 35px;"
          >
            <button
              @click="playVideo"
              style="margin-left: 10px; margin-right: 10px;"
              class="video-btn"
            >
              <img
                class="play-hover"
                style="padding-right: 8px; height: 16px; width: 16px; margin-top: -3px;"
                src="@/assets/play_minimal.svg"
              >
                Watch Video
            </button>
            <button
              @click="downloadPdf"
              style="margin-left: 10px; margin-right: 10px;"
              class="download-btn"
            >
              <img class="play-hover"
                style="padding-right: 8px; height: 22px; width: 22px; margin-top: -3px;"
                src="@/assets/download.svg"
              >
                Download Info
            </button>
          </div>
        </div>
        
      </div>
    
    </div>

    <div class="form-container">
      <div class="jdp-accent" style=""></div>

      <div
        v-if="fail"
        class="d-flex flex-col justify-content-center align-items-center text-white"
        style="width: 98%; margin-right: auto; margin-left: auto; margin-bottom: 0px; margin-top: 8px; border-radius: 5px; background-color: #5e1017;">
        <p
          style="margin: 0px; padding: 15px 0px 0px 10px;
          font-size: 1.3em;"
        >
          This information needs some attention.
        </p>
        <p style="margin: 0px; padding: 5px 0px 15px 10px;">Please correct and resubmit.</p>
      </div>

      <div
        v-if="success"
        class="text-center d-flex flex-col justify-content-center align-items center text-white"
        style="width: 98%; margin: 8px auto 0px auto; border-radius: 5px; background-color: #0087FB">
        <p style="margin: 0px; padding: 15px 0px 0px 10px; font-size: 1.3em;">The ball is rolling!</p>
        <p style="width: 80%; margin: 0px; padding: 5px 0px 15px 10px; margin-left: auto; margin-right: auto;">We'll get to work setting you up. Please contact StellantisEnrollments@jdpa.com with any questions.</p>
      </div>

      <form
        @submit.prevent="formSubmit"
        @reset.prevent="formReset"
        id="enroll_form"
      >
        <div class="form-half-row">
          <label for="dealer_code">Dealer Code</label>
          <input
            v-on:keyup="validateDealerCode"
            v-on:blur="validateDealerCode"
            v-model.trim="form.dealer_code"
            :class="{ 'error' : validation.dealer_code == false, 'success' : validation.dealer_code == true }"
            class="w-100 mt-1 enrollment-input"
            type="text"
            autocomplete="off"
            name="dealer_code"
          >
          <p v-show="validation.dealer_code == false" class="error-text w-100">
            Please enter your 5-digit dealer code
          </p>
        </div>

        <div class="form-half-row">
          <label for="dealer_name">Dealership</label>
          <input
            v-on:keyup="validateDealerName"
            v-on:blur="validateDealerName"
            v-model.trim="form.dealer_name"
            :class="{ 'error' : validation.dealer_name == false, 'success' : validation.dealer_name == true }"
            class="w-100 mt-1"
            type="text"
            autocomplete="off"
            name="dealer_name"
          >
          <p v-show="validation.dealer_name == false" class="error-text w-100">
            Please enter your dealership name
          </p>
        </div>

        <div class="form-half-row">
          <label for="first_name">First Name</label>
          <input
            v-on:keyup="validateFirstName"
            v-on:blur="validateFirstName"
            v-model.trim="form.first_name"
            :class="{ 'error' : validation.first_name == false, 'success' : validation.first_name == true }"
            class="w-100 mt-1"
            type="text"
            name="first_name"
          >
          <p v-show="validation.first_name == false" class="error-text w-100">
            Please enter your first name
          </p>
        </div>

        <div class="form-half-row">
          <label for="last_name">Last Name</label>
          <input
            v-on:keyup="validateLastName"
            v-on:blur="validateLastName"
            v-model.trim="form.last_name"
            :class="{ 'error' : validation.last_name == false, 'success' : validation.last_name == true }"
            class="w-100 mt-1"
            type="text"
            name="last_name"            
          >
          <p v-show="validation.last_name == false" class="error-text w-100">
            Please enter your last name
          </p>
        </div>

        <div class="form-half-row">
          <label for="phone">Phone</label>
          <input
            v-on:keyup="validatePhone"
            v-on:blur="validatePhone"
            v-model.trim="form.phone"
            :class="{ 'error' : validation.phone == false, 'success' : validation.phone == true }"
            class="w-100 mt-1"
            type="tel"
            name="phone"
            placeholder="xxx-xxx-xxxx"
          >
          <p v-show="validation.phone == false" class="error-text w-100">
            Like this please: xxx-xxx-xxxx
          </p>
        </div>

        <div class="form-half-row">
          <label for="phone">Email</label>
          <input type="email"
            :class="{ 'error' : validation.email == false, 'success' : validation.email == true }"
            v-on:keyup="validateEmail"
            v-on:blur="validateEmail"
            class="w-100 mt-1"
            v-model.trim="form.email"
            name="email"
          >
          <p v-show="validation.email == false" class="error-text w-100">
            Please enter your email address
          </p>
        </div>

        <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center;" class="form-row consent-container">
          <input
            required
            style="width: 25px !important; height: 25px !important;"
            type="checkbox"
            :class="{ 'error' : validation.consent == false }" v-model="form.consent" name="consent"
          >
          <p v-show="validation.consent == false" class="w-100" style="color: white; font-size: 0.8em; margin-bottom: 5px;">
            Please agree to the program terms and conditions.
          </p>
          <label style="color: rgba(255,255,255,0.7); margin-left: 8px; font-size: .7em;" for="consent">I agree to enroll my dealership in the J.D. Power Gravity Digital Lead Recapture email solution.</label>
        </div>

        <div class="form-row" style="margin-top: 35px;">
          <button v-if="!processing" type="submit" class="jdp-btn">Enroll</button>
          <button v-if="processing" disabled class="jdp-btn btn-disabled">Saving...</button>
        </div>

      </form>
      
      <div class="w-100 d-flex flex-row justify-content-end">
        <img src="@/assets/jdp_logo.svg" style="width: 150px; padding: 20px;" class="jdp-logo">
        <img src="@/assets/stellantis_digital_logo_white_sm.png" style="width: 150px; padding: 20px;" class="jdp-logo">
      </div>
    
    </div>

    <div style="max-width: 650px; text-align: center; margin: 70px auto 0px auto;">
      <p style="color: white; font-size: 0.9em; margin-bottom: 5px;">
        For more information, please check out 
      </p>
      <a href="https://stellantisdigitalcertified.com/traffic-drivers?sub=email&vid=147371" style="text-decoration: none; color: white;">stellantisdigitalcertified.com</a>
      <p style="color: grey; font-size: .7em; margin-top: 50px; margin-bottom: 75px;">
        ©2022 FCA US LLC. All Rights Reserved. Chrysler, Dodge, Jeep, Ram, Mopar and SRT are registered trademarks of FCA US LLC. ALFA ROMEO and FIAT are registered trademarks of FCA Group Marketing S.p.A., used with permission.</p>
    </div>
    
  </div>

  <!-- end default container -->
</template>

<script>

import emailjs from '@emailjs/browser';

export default {
  name: 'Enroll',
  components: {
  },

  created() {
    document.title = 'Gravity Email Program Enrollment';
  },

  data() {
    return {
      showButtons: this.$route.query.buttons,
      mp4Url: 'https://unityworks-a.akamaihd.net/anon.unityworks/4/Y/E/47026746_Power_Gravity_Digital_Lead_Recapture_220111041128_4.mp4',
      pdfUrl: 'https://stellantisdigitalcertified.com/uploads/vendor_pdfs/autodata_1642097889.pdf',
      processing: false, 
      success: false,
      fail: false,
      form: {
        dealer_code: '',
        dealer_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        consent: '',
        source: 'Not Specified',
      },
      validation: {
        dealer_code: null,
        dealer_name: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
      }
    };
  },

  methods: {

    playVideo() {
      this.$store.commit('toggleModal');
      this.$store.commit("setVideoActiveUrl", this.mp4Url);
      this.$store.commit('toggleVideo');
    },

    downloadPdf() {
      window.open(this.pdfUrl);
    },

    formSubmit() {
      this.processing = true;
      this.validateDealerCode();
      this.validateDealerName();
      this.validateFirstName();
      this.validateLastName();
      this.validatePhone();
      this.validateEmail();
      this.validateConsent();
      let fullyValidated = Object.values(this.validation).every(Boolean);
      
      if(!fullyValidated) {
        this.fail = true;
      } else {
        let self = this;
        this.sendEmail()
          .then(function(response) {
            // console.log('SUCCESS!', response.status, response.text);
            if(response.status == '200') {
              self.fail = false;
              self.success = true;
              self.formReset();
            } else {
              alert('Whoops - we have a problem with this form. Please call Troy to enroll, and let him know this is broken: (952) 838-9124');
            }
          }, function() {
              alert('Whoops - we have a problem with this form. Please call Troy to enroll, and let him know this is broken: (952) 838-9124');
              // console.log('FAILED...', error);
          });
      }
      this.processing = false;
    },

    sendEmail() {
      let data = {
        dealer_code: this.form.dealer_code,
        dealer_name: this.form.dealer_name,
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        phone: this.form.phone,
        source: this.form.source,
        from: this.form.from,
        cc: this.form.email,
      }

      // we get a promise back from email js
      return emailjs.send('service_mlaispy', 'template_fos3zqa', data, process.env.VUE_APP_EMAIL_JS_USER);
    },

    validateDealerCode() {
      if(this.form.dealer_code.length == 5) {
        this.validation.dealer_code = true;
      } else {
        this.validation.dealer_code = false;
      }
    },

    validateDealerName() {
      if(this.form.dealer_name.length > 5) {
        this.validation.dealer_name = true;
      } else {
        this.validation.dealer_name = false;
      }
    },

    validateFirstName() {
      if(this.form.first_name.length > 0) {
        this.validation.first_name = true;
      } else {
        this.validation.first_name = false;
      }
    },

    validateLastName() {
      if(this.form.last_name.length > 0) {
        this.validation.last_name = true;
      } else {
        this.validation.last_name = false;
      }
    },

    validatePhone() {
      let regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      let valid = String(this.form.phone).match(regex);
      this.validation.phone = valid ? true : false;
    },

    validateEmail() {
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let valid = String(this.form.email).toLowerCase().match(regex);
      this.validation.email = valid ? true : false;
    },

    validateConsent() {
      if(this.form.consent) {
        this.validation.consent = true;
      } else {
        this.validation.consent = false;
      }
    },

    formReset() {
      this.form.dealer_code = '';
      this.form.dealer_name = '';
      this.form.first_name = '';
      this.form.last_name = '';
      this.form.email = '';
      this.form.phone = '';
      this.form.consent = '';

      Object.keys(this.validation).forEach((i) => this.validation[i] = null);
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    },

  },

  computed: {

    videoActive() {
      return this.$store.state.videoActive;
    },

    videoActiveUrl() {
      return this.$store.state.videoActiveUrl;
    },

  },

};
</script>

<style scoped>

  .jdp-btn {
    font-weight: 600;
    line-height: 1;
    padding: 13px 10px 10px 10px;
    color: #FFFFFF;
    background-color: #0087FB;
    background-image: none;
    background-size: 200% auto;
    border-radius: 2px;
    border: none;
    text-transform: uppercase;
    font-size: 1em;
  }

  .jdp-btn:hover {
    cursor: pointer;
    background-color: #0075db;
    background-size: 100% auto;
  }

  .btn-disabled {
    background-color: #004a8b;
    opacity: 0.7;
  }

  .btn-disabled:hover {
    background-color: #004a8b;
    opacity: 0.7;
    cursor: wait !important;
  }

  .btn-disabled:focus {
    outline: none;
  }

  .video-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 10px 10px 10px;
    font-weight: 400;
    color: #FFFFFF;
    background: none;
    border-radius: 2px;
    outline: none;
    border: 1px solid rgba(255,255,255,0.5);
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .video-btn:hover {
    background-color: rgba(0,0,0,.5);
    cursor: pointer;
  }

  .download-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 10px 10px 10px;
    font-weight: 400;
    color: #FFFFFF;
    background: none;
    border-radius: 2px;
    outline: none;
    border: 1px solid rgba(255,255,255,0.5);
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .download-btn:hover {
    background-color: rgba(0,0,0,.5);
    cursor: pointer;
  }

  .form-container {
    margin-top: 30px;
    background-color: rgba(0, 0, 0, .7);
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: -2px 6px 20px 10px rgba(0,0,0,0.5); 
    box-shadow: -2px 6px 20px 10px rgba(0,0,0,0.5);
  }

  .jdp-accent {
    background-image: linear-gradient(90deg,#0078e0 0%,#33e778 100%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    height: 5px;
  }

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 35px 45px 0px 45px;
  }

  form a {
    color: white;
  }

  form a:hover {
    color: #0078e0;
  }

  .form-row {
    margin-top: 30px;
    width: 90%;
  }

  .consent-container {
    width: 70%;
  }

  /* desktop */
  @media only screen and (min-width: 700px) {
    .form-half-row {
      width: 45%;
      margin-top: 30px;
    }
  }

  /* mobile */
  @media only screen and (max-width: 699px) {

    .consent-container {
      width: 90%;
    }

    .jdp-accent {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    .form-half-row {
      width: 90%;
      margin-top: 30px;
    }

    .form-container {
      border-radius: 0px;
    }

    form {
      justify-content: center;
    }

    .jdp-btn {
      width: 100% !important;
      margin-bottom: 35px;
    }

    .jdp-logo {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
    }

  }

  label {
    color: white;
  }

  input[type=text], input[type=tel], input[type=email] {
    height: 2.5em;
    border: 1px solid grey;
    border-radius: 4px;
    color: black;
    padding-left: 10px;
    font-size: 1.1em;
    background-size: 25px;
    background-position: right 10px center;
    background-repeat: no-repeat;
    border: 3px solid white;
  }

  input[type=text]:focus, input[type=tel]:focus, input[type=email]:focus {
    background-color: #f6f6f6;
    transition: all 100ms ease-in-out;
    outline: none !important;
    border: 3px solid #33e778;
    box-shadow: 0 0 10px black;
  }

  input[type=checkbox]:focus {
    outline: 3px solid #33e778;
  }

  button:focus {
    outline: 3px solid white;
    opacity: 0.9;
  }

  .error {
    outline: 3px solid #cf202f !important;
  }

  .success {
    background-image: url('~@/assets/check_circle.svg');
    background-size: 25px;
    background-position: right 10px center;
    background-repeat: no-repeat;
  }

  .error-text {
    font-size: 0.8em;
    text-align: right;
    color: white;
    margin-top: 5px;
    animation: fade-in linear 500ms;
    -webkit-animation: fade-in linear 100ms;
    -moz-animation: fade-in linear 100ms;
    -o-animation: fade-in linear 100ms;
    -ms-animation: fade-in linear 100ms;
  }

  @keyframes fade-in {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-moz-keyframes fade {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-webkit-keyframes fade {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-o-keyframes fade {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-ms-keyframes fade {
    0% {opacity:0;}
    100% {opacity:1;}
  }

</style>